<template>
    <div class="projects">
        <navbar-building></navbar-building>
        <div class="empty"></div>
    </div>
</template>

<script>
import NavbarBuilding from '../components/NavbarBuilding.vue';
import httpClient from '@/services/http.service';
    export default {
    components: { NavbarBuilding },
        name:'Projects',
    data(){
        return{
            category:this.$route.params.category,
            parentCategory:null,
            border:'border-tajhiz',
        }
    },
    async mounted(){
         try {
                const {status , data} = await httpClient.get('/fa/categories',{params:{
                  title:this.$route.params.category,
                  expand:'childs,'
                }})
                if (status == 200) {
                  if(data[0].parentCategory == null){
                    this.parentCategory = null;
                  }else{
                    this.parentCategory = data[0].parentCategory.title;
                  }
                  
                }
              } catch (e) {
                this.errors = 'Conection Is Faild';
              }
        if (this.category == 'plast') {
            this.border = 'border-plast';
        }
        
    }
    }
</script>

<style lang="scss">
body{
  overflow-y: visible;
}
.empty{
    height: 100px;
}
@media (max-width: 600px){
    .empty{
        height: 20px;
    }
}
</style>
